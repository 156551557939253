<template>
	<div class="container">
		<div class="row progress-bar-container justify-content-center align-items-center text-light">
			<div class="col-1">
				<b>Steps:</b>
			</div>
			<div class="col-10">
				<div class="progress" style="height: 3px;">
					<div class="progress-bar bg-light" role="progressbar" style="width: 100%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>
			<div class="col-1 text-center">
				<b>2 of 2</b>
			</div>
		</div>
		<div class="row header mt-10">
			<div class="col">
				<h3>Creating New Course</h3>
			</div>
		</div>

		<div class="row new-course">
			<div class="col-6">

				<div class="brand-form">
					<div class="form-group readonly mb-9">
						<label>{{ newCourseName }}</label>
					</div>
					<div class="form-group">
						<label>Select the basic template provided by academy to begin developing this course.</label>

						<div class="new-course-card-container">
							<figure
								class="admin-default-big-card"
								:class="{
									'admin-default-big-card--selected': template === 'basic'
								}"
								@click="$emit('selectTemplate', 'basic')"
							>
								<label
									for="new-course-template-file"
									class="btn btn-light shadow-none"
									>Basic Template
								</label>
							</figure>

							<!-- <figure
								class="admin-default-big-card"
								:class="{
									'admin-default-big-card--selected': template === 'file'
								}"
								@click="$emit('selectTemplate', 'file')"
							>
								<v-file-input
									v-if="template === 'file'"
									id="new-course-template-file"
									v-model="templateFile"
									chips
									truncate-length="50"
									accept=".csv"
									:loading="isLoading"
									class="template-input"
								></v-file-input>
								<label
									for="new-course-template-file"
									class="btn btn-light shadow-none"
									>Upload Content from file
								</label> -->
							</figure>
						</div>

					</div>
				</div>

			</div>

			<div class="col-12">
				<div class="row justify-content-center align-items-center">
					<div class="col">
						<FormMessage v-if="message">{{ message }}</FormMessage>
					</div>
					<div class="col text-right">

						<v-btn
							class="admin-primary-button primary-contrast-background btn-outline mr-5 shadow-none"
							@click="goBack"
							>Back</v-btn
						>
						<v-btn
							class="admin-primary-button primary-contrast-background shadow-none"
							:loading="isLoading"
							:disabled="isLoading"
							@click="createNewCourse"
							>Create</v-btn
						>
					</div>
				</div>
			</div>

		</div>

	</div>
	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="8" class="pa-0">
				<admin-default-subheader>
					Creating New Course
				</admin-default-subheader>
				<admin-default-header>
					{{ newCourseName }}
				</admin-default-header> </v-col
			><v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> 2 of 2</admin-default-subheader>
			</v-col>
		</v-row>
		<v-row class="mt-16">
			<admin-default-subheader>
				How would you like to start?
			</admin-default-subheader>
		</v-row>
		<v-row>
			<v-container class="new-course-card-container mb-16 mt-4">
				<figure
					class="admin-default-big-card"
					:class="{
						'admin-default-big-card--selected': template === 'basic'
					}"
					@click="$emit('selectTemplate', 'basic')"
				>
					<v-btn
						class="admin-primary-button secondary-contrast-background"
						>Basic Template</v-btn
					>
				</figure>

				<figure
					class="admin-default-big-card"
					:class="{
						'admin-default-big-card--selected': template === 'file'
					}"
					@click="$emit('selectTemplate', 'file')"
				>
					<v-file-input
						v-if="template === 'file'"
						id="new-course-template-file"
						v-model="templateFile"
						chips
						truncate-length="50"
						accept=".csv"
						:loading="isLoading"
						class="template-input"
					></v-file-input>
					<label
						for="new-course-template-file"
						class="admin-primary-button secondary-contrast-background"
						>Upload Content from file
					</label>
				</figure>
			</v-container>
		</v-row>
		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row class="mt-16">
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background mr-6"
					:loading="isLoading"
					:disabled="isLoading"
					@click="goBack"
					>Back</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="createNewCourse"
					>Create</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import FormMessage from "@/components/FormMessage.vue";
import { mapGetters } from "vuex";

export default {
	name: "NewCourseStep2",
	props: ["template", "newCourseName", "message", "templateFile"],
	components: {
		AdminDefaultSubheader,
		AdminDefaultHeader,
		FormMessage
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {};
	},
	methods: {
		async valide() {
			this.$emit("setMessage", "");
			if (this.template === "file") {
				this.$emit(
					"setMessage",
					"Content from file is not available yet, please select basic template"
				);
				return false;
			}
			return true;
		},
		async createNewCourse() {
			if ((await this.valide()) === false) return;
			this.$emit("createCourse");
		},
		async goBack() {
			if ((await this.valide()) === false) return;
			this.$emit("selectComponent", "NewCourseStep1");
		}
	}
};
</script>

<style></style>
